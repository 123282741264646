<template>
	<div class="main">
		<div class="searchw">
			<Form :model="formData" :label-width="70" inline>
				<FormItem label="手机号">
					<Input v-model="formData.phone" placeholder="手机号" @on-keyup.13="init"></Input>
				</FormItem>
				<Button type="primary" icon="md-search" @click="init">搜索</Button>
			</Form>
		</div>
		<div class="ivu-table-wrapper">
			<Table size="small" :columns="columns" :data="data"></Table>
		</div>
		<div class="footer-m">
			<Page class-name="pagew page-r" :total="total" :page-size="pageSize" :page-size-opts="[15,30,50]" show-sizer show-total @on-change="changePage" @on-page-size-change="changePageSize"></Page>
		</div>
	</div>
</template>

<script>
	export default {
		name: "SmsCode",
		data(){
			return{
				formData:{phone:''},
				columns:[
					{title:'手机号',key:'phone'},
					{title:'短信验证码',key:'code'},
					{title:'发送时间',key:'send_time'},
					{title:'过期时间',key:'out_time'},
				],
				data:[],
				total:0,
				pageSize:15,
				page:1
			}
		},
		created() {
			this.init();
		},
		methods:{
			init(){
				var _this = this;
				this.requestApi('/adm/get_sms_code.html',{search:this.formData,page:this.page,pageSize: this.pageSize}).then(function (res) {
					if(res.status){
						_this.data = res.data.data;
						_this.total= res.data.total;
					}
				})
			},
			changePage(page){
				this.page = page;
				this.init();
			},
			changePageSize(size){
				this.page = 1;
				this.pageSize = size;
				this.init();
			},
		}
	}
</script>

<style lang="less">
.nouse{}
</style>
